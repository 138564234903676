import Link from 'next/link'
import styled from '@emotion/styled'
import { PostData } from '../../common/types'
import { toHunDate } from '../../common/date'

const Styled = {
  Article: styled.article({
    fontFamily: 'var(--font-sans)',
    padding: '.625rem',
    maxWidth: '30rem',
    boxShadow: '0 0 0 1px var(--color-grey)',
    borderRadius: 8,
    margin: '.825rem',
  }),
  Heading: styled(Link)({
    color: 'var(--color-bright)',
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 600,
    
    '&:hover': {
      color: 'var(--color-bubblegum)'
    }
  }),
  Date: styled.time({
    fontSize: 12
  })
}

export const BlogPost = ({ slug, meta, lastModified }: PostData) => (
  <Styled.Article key={slug}>
    <div>
      <Styled.Heading href={`/blog/${slug}`} locale={false}>
        {meta.title}
      </Styled.Heading>
    </div>
    <Styled.Date>{toHunDate(lastModified)}</Styled.Date>
  </Styled.Article>
)
