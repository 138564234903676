import styled from '@emotion/styled'

const Styled = {
  Footer: styled.footer({
    color: '#394c54',
    flexShrink: 0,
    padding: '5rem 0 var(--leading)',
    textAlign: 'center',
    fontSize: 'calc(0.8 * var(--base))',
    margin: '0 auto',
    width: '100%',
    backgroundImage: 'radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
    
    a: {
      color: '#394c54',
      
      '&:hover': {
        color: 'var(--color-bubblegum)'
      },
    },
  }),
  FooterIcons: styled.div({
    margin: '2rem',
  }),
  FooterIcon: styled.svg({
    width: 24,
    height: 24,
    padding: 8,
    fill: '#394c54',
    
    '&:hover': {
      fill: 'var(--color-bubblegum)',
    }
  })
}

const LinkedInIcon = (): JSX.Element => (
  <Styled.FooterIcon viewBox="0 0 16 16">
    <path d="M14.8,0H1.2C0.5,0,0,0.5,0,1.2v13.7C0,15.5,0.5,16,1.2,16h13.6c0.7,0,1.2-0.5,1.2-1.2V1.2 C16,0.5,15.5,0,14.8,0z M4.7,13.6H2.4V6h2.4V13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4 C4.9,4.3,4.3,5,3.6,5z M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2c-1.2,0-1.4,1-1.4,2v3.8H6.2V6h2.3v1h0c0.3-0.6,1.1-1.2,2.2-1.2 c2.4,0,2.8,1.6,2.8,3.6V13.6z" />
  </Styled.FooterIcon>
)

const GitHubIcon = (): JSX.Element => (
  <Styled.FooterIcon viewBox="0 0 24 24">
    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
  </Styled.FooterIcon>
)

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  
  return (
    <Styled.Footer>
      <Styled.FooterIcons>
        <a href="https://linkedin.com/in/violapeter" aria-label="My LinkedIn page">
          <LinkedInIcon />
        </a>
        <a href="https://github.com/violapeter" aria-label="My GitHub profile">
          <GitHubIcon />
        </a>
      </Styled.FooterIcons>
      <span>© Copyright {currentYear}, </span>
      <a href="https://violapeter.hu">Péter Viola</a>
    </Styled.Footer>
  )
}
