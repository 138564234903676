import { PostData } from '../../common/types'
import styled from '@emotion/styled'
import { BlogPost } from './BlogPost'

const Styled = {
  List: styled.div({
    flex: 1,
    maxWidth: '30vw',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
  
    '@media (max-width: 1280px)': {
      maxWidth: '60vw',
    },
  }),
}

export const PostList = ({ posts }: { posts: PostData[] }) => (
  <Styled.List>
    {posts.map((post) => (
      <BlogPost key={`post-${post.slug}`} {...post}/>
    ))}
  </Styled.List>
)
