import styled from '@emotion/styled'
import { ReactNode } from 'react'

interface ParallaxWrapperProps {
  children: ReactNode
}

export const PERSPECTIVE_BASE = 100

const StyledParallaxWrapper = styled.div({
  perspective: PERSPECTIVE_BASE,
  position: 'relative',
  height: '100vh',
  overflowX: 'hidden',
  overflowY: 'auto',
})

export const ParallaxWrapper = ({ children }: ParallaxWrapperProps) => (
  <StyledParallaxWrapper>{children}</StyledParallaxWrapper>
)
