import styled from '@emotion/styled'
import { forwardRef } from 'react'

const StyledLogo = styled.svg({
  display: 'block',
  padding: 4,
  fill: '#070f12',
  filter: 'drop-shadow(0 1px 0 rgba(255, 255, 255, .1))',
  overflow: 'visible',
  transition: 'fill .2s ease-in-out, filter .2s ease-in-out',
  
  '&:hover g, &:focus-within g': {
    fill: 'var(--color-bubblegum)',
    filter: 'drop-shadow(0 0 400px var(--color-bubblegum))',
  },
})

const Logo = forwardRef<HTMLAnchorElement>((props, forwardedRef) => (
  <a {...props} ref={forwardedRef} aria-label={'Péter Viola'}>
    <StyledLogo
      width="48px"
      height="48px"
      viewBox="0 0 1024 1024"
    >
      <g>
        <rect width="100%" height="100%" fill="transparent" />
        <polygon points="591.9,908.9 385.2,908.9 0,115.1 191.6,117.5"/>
        <path
          d="M710.7,115.1h-426l81,162.5h329.8c90,0,146.2,43.2,146.2,123.9v2.3c0,70.2-52.6,123.9-142.7,123.9H492.2 l81,160.2h119.9c183.5,0,330.9-98.2,330.9-287.6V398C1024,230.8,905.9,115.1,710.7,115.1z"
        />
      </g>
    </StyledLogo>
  </a>
))

Logo.displayName = 'Logo'

export default Logo
