import { Parallax } from '../parallax/Parallax'
import { Footer } from '../layout/Footer'
import styled from '@emotion/styled'
import { Header } from '../layout/Header'
import { PostData } from '../../common/types'
import dynamic from 'next/dynamic'
import { PostList } from '../blog/PostList'
const Scenery = dynamic(() => import('../scenery/Scenery'))
import Link from 'next/link'

const Styled = {
  BlogHeader: styled(Header)({
    padding: '4rem 0'
  }),
  BlogWrapper: styled.div({
    background: 'var(--color-dark)',
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transformStyle: 'preserve-3d',
  }),
  Content: styled.div({
    maxWidth: '20vw',
    margin: '0 auto',
    
    p: {
      textIndent: '1rem',
      textAlign: 'justify'
    },
    
    '@media (max-width: 1280px)': {
      maxWidth: '60vw',
      minWidth: '300px',
    },
  }),
  Title: styled.h1({
    textAlign: 'center',
  }),
  BlogList: styled.div({
    flex: 1,
  }),
}

export const Main = ({ posts }: { posts: PostData[] }): JSX.Element => (
  <>
    <Scenery />
    <Styled.BlogWrapper>
      <Styled.BlogList>
        <Styled.BlogHeader />
        <Styled.Content>
          <Styled.Title>Hello</Styled.Title>
          <p>I am Peter Viola, Staff Software Engineer at LastPass, the leading password manager.</p>
          <p>Don’t hesitate to reach me out.</p>
        
          
          {posts.length > 0 && (
            <>
              <Link href="/blog">
                <h3>Blog</h3>
              </Link>
              <p>I wrote some articles in hungarian. I didn’t translate them because they’re mostly meaningless in another language.</p>
              <PostList posts={posts}/>
            </>
          )}
        </Styled.Content>
      </Styled.BlogList>
      <Footer />
    </Styled.BlogWrapper>
  </>
)
