import type { NextPage } from 'next'
import { PostData } from '../common/types'
import { ParallaxWrapper } from '../components/parallax/ParallaxWrapper'
import { getPosts } from '../common/post'
import { Main } from '../components/main/Main'

interface Props {
  posts: PostData[]
}

const Home: NextPage<Props> = ({ posts }) => (
  <ParallaxWrapper>
    <Main posts={posts}/>
  </ParallaxWrapper>
)

export const getStaticProps = async () => ({
  props: {
    posts: await getPosts(),
  }
})

export default Home
