import styled from '@emotion/styled'
import Link from 'next/link'
import Logo from './Logo'

const StyledHeader = styled.header({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '4rem 0 0',
  width: '100%',
  maxWidth: 'var(--layout-max-width)',
})

export const Header = (props: Record<string, unknown>) => (
  <StyledHeader {...props}>
    <Link href="/" passHref legacyBehavior>
      <Logo />
    </Link>
  </StyledHeader>
)
