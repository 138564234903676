const createFormatter = (shortMonth = false) => Intl.DateTimeFormat('hu', {
  year: 'numeric',
  month: shortMonth ? 'short' : 'long',
  day: 'numeric',
})

type DateLike = Date | string | number

const normalize = (date: DateLike): Date => date instanceof Date ? date : new Date(date)

export const toHunDate = (date: DateLike) => createFormatter().format(normalize(date))

export const toShortHunDate = (date: DateLike) => [
  Intl.DateTimeFormat('hu', { month: 'short' }).format(normalize(date)),
  normalize(date).getDate()
].join(' ')
